import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationLayoutComponent} from "./shared/layouts/authentication-layout/authentication-layout.component";
import {MainLayoutComponent} from "./shared/layouts/main-layout/main-layout.component";
import {UserResolver} from "./core/resolvers/user.resolver";
import {PermissionResolver} from "./core/resolvers/permission.resolver";
import {OrganizationSubscriptionResolver} from "./core/resolvers/organization.resolver";
import {GuestLoginLayoutComponent} from "./shared/layouts/guest-login-layout/guest-login-layout.component";
import {GuestLinkLayoutComponent} from "./shared/layouts/guest-link-layout/guest-link-layout.component";

const routes: Routes = [
  {
    path: 'auth',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
      },
    ]
  },
  {
    path: 'guest',
    component: GuestLoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule)
      },
    ]
  },
  {
    path: 'invitation',
    component: GuestLinkLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/guest-link/guest-link.module').then(m => m.GuestLinkModule)
      },
      {path: '**', redirectTo: '/invitation/invalid', pathMatch: 'full'},
    ]
  },
  {
    path: 'crm',
    loadChildren: () => import('./modules/crm/crm.module').then(m => m.CrmModule),
    resolve: {user: UserResolver, perms: PermissionResolver, subscription: OrganizationSubscriptionResolver,},
    data: {
      breadcrumb: 'CRM'
    },
  },
  {
    path: '',
    component: MainLayoutComponent,
    resolve: {user: UserResolver, perms: PermissionResolver, subscription: OrganizationSubscriptionResolver,},
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          noBreadcrumb: true,
          // breadcrumb: 'Dashboard'
        },
      },
      {
        path: 'search',
        loadChildren: () => import('./modules/quick-search/quick-search.module').then(m => m.QuickSearchModule),
        data: {
          noBreadcrumb: true,
          // breadcrumb: 'Search'
        },
      },
      {
        path: 'builder',
        loadChildren: () => import('./modules/builder/builder.module').then(m => m.BuilderModule),
        data: {
          breadcrumb: 'Builder'
        },
      },
      {
        path: 'project',
        loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule),
        data: {
          breadcrumb: 'Project'
        },
      },
      {
        path: 'display-project',
        loadChildren: () => import('./modules/display-project/display-project.module').then(m => m.DisplayProjectModule),
        data: {
          breadcrumb: 'Display Project'
        },
      },
      {
        path: 'property',
        loadChildren: () => import('./modules/property/property.module').then(m => m.PropertyModule),
        data: {
          breadcrumb: 'Property'
        },
      },
      {
        path: 'enquiry',
        loadChildren: () => import('./modules/enquiry/enquiry.module').then(m => m.EnquiryModule),
        data: {
          breadcrumb: 'Enquiry'
        },
      },
      {
        path: 'marketing',
        loadChildren: () => import('./modules/marketing/marketing.module').then(m => m.MarketingModule),
        data: {
          breadcrumb: 'Marketing'
        },
      },
      {
        path: 'media',
        loadChildren: () => import('./modules/media/media.module').then(m => m.MediaModule),
        data: {
          breadcrumb: 'Media'
        },
      },
      {
        path: 'manage',
        loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule),
        data: {
          breadcrumb: 'Manage'
        },
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
        data: {
          breadcrumb: 'Notification'
        },
      },
      {
        path: 'article',
        loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule),
        data: {
          breadcrumb: 'Article'
        },
      },
      {path: '**', redirectTo: '/project/list', pathMatch: 'full'},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
